@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&family=Nunito+Sans:wght@200&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  font-family: 'Nunito Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

/* Landing page style */
.landing-page {
  display: flex;
  align-items: center; 
  height: 100vh;
  background-color: whitesmoke;
}

.name-div {
  width: 60%;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
}

.name {
  font-family: 'Archivo Narrow', sans-serif;
  font-size: 48px;
}

.title {
  margin-top: -30px;
}

.image-div {
  width: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.324);
  border: 1px solid black;
}
/* End of landing page */

/* AboutMe style */

.about-me-page {
  height: 100vh;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-top: 1px solid black;
}

.page-title {
  font-family: 'Archivo Narrow', sans-serif;
  font-size: 32px;
}

.about-me-content {
  margin: auto auto;
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.324);
  border: 1px solid black;
  width: 70%;
  
}

.about-me-p {
  line-height: 2;
  padding: 10px;
}

.sidebar-button {
  margin: 20px;
  width: 10rem;
  height: 2rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.324);
  border: 1px solid black;
  border-radius: 5px;
}

.sidebar.show {
  left: 0; /* Show the sidebar */
}

.sidebar.hidden {
  display: none; /* Hide the sidebar */
}

.sidebar-container {
  display: flex;
  justify-content: center;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 40%;
  height: 100vh;
  background-color: black;
  color: white;
  transition: left 0.3s ease-in-out;
  z-index: 999;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  flex-shrink: 0;
}

/* .sidebar-content {
  margin-top: 3%;
} */

.sidebar h3 {
  margin-top: 5%;
  font-family: 'Archivo Narrow', sans-serif;
}

.sidebar ul {
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  list-style-type: none;
  margin-bottom: 10px;
}

svg{
  padding: 7px ;
}

.links {
  font-size: 18px;
}

/* End of aboutme style */

/* Projects style */
.projects-page {
  height: 100%;
  background-color: whitesmoke;
  border-top: 1px solid black;

  }

.project-h1 {
  text-align: center;
  padding-bottom: 20px;
  font-size: 32px;
  font-family: 'Archivo Narrow', sans-serif;
}
  
.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}

.project-wrapper {
  width: 48%;
  padding: 10px;
}

.project {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.324);
  border: 1px solid black;
  align-items: center;
  padding: 12px;
  
}

.project-image-container {
  width: 100%; 
}

.project-image {
  height: auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 5px -5px 15px rgba(117, 117, 117, 0.324);
}

.project-info {
  width: 100%;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.324);
}

.project-description {
  margin-left: 5px;
}

.techStack {
  margin-top: -15px;
}

.project h3 {
  margin: 10px 5px;
  
}

.falink-link {
  color: rgb(24, 119, 209) !important;
}

.falink-video {
  color: rgb(238, 53, 53) !important;
}

/* .project-description {
  
} */

.project-links {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}

.fa-lg {
  margin: -7px;
}

/* End of projects style */

/* Footer style */
footer {
  text-align: center;
  /* height: 2rem; */
  padding: 30px;
  color: rgb(208, 208, 208);
}

/* End footer style */

@media (max-width: 600px) {
  .landing-page {
      display: block;
      height: 100%;
      background-color: whitesmoke;
  }

  .name-div {
    margin: auto;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
  }
  
  .image-div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: auto;
  }

}


@media (max-width: 929px) {
  .about-me-content {
    margin: auto auto;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.324);
    border: 1px solid black;
    width: 90%;
  }
  .image-div {
    width: 90%;
    height: 100vh;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    
  }
}

@media (max-width: 999px) {
  .project-wrapper {
    width: 100%;
    padding: 25px;
  }
}

@media (max-width: 1027px) {
  .about-me-page {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid black;
  }
}